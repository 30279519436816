<template>
  <div>
    <el-card v-loading="isLoading" shadow="always">
      <template #header>
        <el-row type="flex" justify="space-between" align="center">
          <el-col :md="8" :sm="8" :xs="10"
            ><el-row type="flex" justify="start"
              ><h4>Relatório de Pedidos</h4></el-row
            >
          </el-col>
          <el-col :md="16">
            <el-row type="flex" justify="end" align="center" :gutter="8">
              <el-button
                type="primary"
                icon="el-icon-s-operation"
                size="medium"
                @click="openFilterOrdersModal()"
              ></el-button>
              <el-button
                type="primary"
                size="medium"
                plain
                @click="() => createPDF({ created_at: false }, 'date')"
                >Imprimir</el-button
              >
            </el-row>
          </el-col>
        </el-row>
      </template>
      <el-empty
        v-if="!orders"
        description="Utilize os filtros para buscar os pedidos"
      ></el-empty>
      <div v-for="(orders, client) in GroupedOrders" :key="client" v-else>
        <h2 class="el-table__subheader_name">{{ client }}</h2>
        <el-table
          stripe
          :cell-style="() => 'text-align:center;'"
          show-summary
          :summary-method="(v) => calcSummary(v, orders)"
          sum-text="Total do cliente"
          :data="orders"
          style="width: 100%; z-index: 0"
        >
          <el-table-column label="pedido" prop="code"></el-table-column>
          <el-table-column label="data pedido" width="100">
            <template #default="{ row: order }">
              <span>
                {{
                  order.created_at
                    ? dateFormatter.format(new Date(order.created_at))
                    : "-"
                }}
              </span>
              {{ " " }}
              <span>
                {{
                  order.created_at
                    ? timeFormatter.format(new Date(order.created_at))
                    : "-"
                }}
              </span>
            </template>
          </el-table-column>
          <el-table-column prop="client.name" label="cliente">
            <template #default="{ row: order }">
              {{ order.client?.legal?.social_name || order.client?.name }}
            </template>
          </el-table-column>
          <el-table-column
            prop="client.legal.company_name"
            label="razão social"
          >
          </el-table-column>

          <el-table-column
            label="valor"
            :formatter="
              ({ payments }) =>
                currencyFormatter.format(sumAmountPaymentOrders(payments))
            "
          ></el-table-column>
          <el-table-column
            label="pago"
            :formatter="
              ({ payments }) =>
                currencyFormatter.format(sumPaidOrderPayments(payments))
            "
          ></el-table-column>
          <el-table-column
            label="restante"
            :formatter="
              ({ payments, total_amount }) =>
                currencyFormatter.format(
                  sumAmountPaymentOrders(payments) -
                    sumPaidOrderPayments(payments)
                )
            "
          ></el-table-column>
          <el-table-column label="NF" prop="invoice.nfe"></el-table-column>
          <el-table-column label="vencimento">
            <template #default="{ row: order }">
              <span v-for="payment in order.payments" :key="payment">
                {{
                  (payment.expires_at &&
                    dateFormatter.format(new Date(payment.expires_at))) ||
                  "sem vencimento"
                }}
                <br />
              </span>
            </template>
          </el-table-column>
          <el-table-column label="cobrador" prop=""></el-table-column>
          <el-table-column
            label="vendedor"
            prop="seller.name"
          ></el-table-column>
        </el-table>
      </div>
    </el-card>
    <filter-orders-modal
      :showModal="showFilterOrdersModal"
      :filters="filterOptions"
      @close-modal="showFilterOrdersModal = false"
      @update-filters="updateFilters"
    ></filter-orders-modal>
  </div>
</template>

<script>
//import { ElNotification } from "element-plus";
import OrderService from "../services/orders";
import { generatePDF } from "../services/reports";
import {
  dateFormatter,
  currencyFormatter,
  timeFormatter,
  dateFormatterShort,
  dateFormatterLong,
} from "../utils/formatters";
import FilterOrdersModal from "../views/modals/FilterOrdersModal.vue";

export default {
  name: "OrdersCompletedDailyPage",
  data: () => ({
    hasError: false,
    isLoading: false,
    orders: null,
    currencyFormatter,
    dateFormatter,
    showFilterOrdersModal: false,
    filterOptions: {
      status: ["completed"],
    },
    allowedFields: {},
    timeFormatter,
    dateFormatterShort,
    dateFormatterLong,
  }),
  components: { FilterOrdersModal },
  computed: {
    Orders() {
      return this.orders || [];
    },
    GroupedOrders() {
      return this.groupByClientRoute();
    },
  },
  methods: {
    getFirstExpirationOfOrderPayment(payments) {
      const p = payments.sort((a, b) =>
        a.expires_at > b.expires_at ? 1 : -1
      )?.[0];

      return p?.expires_at ? new Date(p?.expires_at) : null;
    },
    openFilterOrdersModal() {
      this.showFilterOrdersModal = true;
    },
    calcSummary({ columns }, orders = []) {
      return columns?.map((column, id) => {
        if (!id) return "Total do cliente";

        if (id === 4)
          return this.currencyFormatter.format(
            orders.reduce(
              (t, o) => t + this.sumAmountPaymentOrders(o.payments),
              0
            )
          );

        if (id === 5)
          return this.currencyFormatter.format(
            orders.reduce(
              (t, o) => t + Number(this.sumPaidOrderPayments(o.payments)),
              0
            )
          );

        if (id === 6)
          return this.currencyFormatter.format(
            orders.reduce(
              (t, o) => t + this.sumAmountPaymentOrders(o.payments),
              0
            ) -
              orders.reduce(
                (t, o) => t + Number(this.sumPaidOrderPayments(o.payments)),
                0
              )
          );

        return "";
      });
    },
    updateFilters(filters) {
      this.filterOptions = filters;
      this.fetchOrders(true);
    },
    groupByClientRoute() {
      const groupedOrders = this.orders?.reduce((t, o) => {
        const clientName =
          o.client?.legal?.social_name || o.client?.name || "SEM ROTA";
        if (t[clientName]) t[clientName].push(o);
        else t[clientName] = [o];

        return t;
      }, {});

      return groupedOrders;
    },
    executeUserCommand(command) {
      switch (command) {
        case "OrderByCreatedAt":
          this.createPDF({ created_at: false }, "date");
          break;
        case "OrderByInvoiceId":
          this.createPDF({ invoice_id: true }, "number");
          break;
      }
    },
    convertOrderValuesByType(first, second, key, orderByType) {
      let f, s;
      switch (orderByType) {
        case "date":
          f = new Date(first[key]);
          s = new Date(second[key]);
          break;
        case "number":
          f = Number(first[key]);
          s = Number(second[key]);
          break;
        default:
          f = first[key];
          s = second[key];
      }

      return { f, s };
    },
    generatePDFContent(orderBy, orderByType) {
      const mappedOrders = Object.values(this.GroupedOrders).map((orders) => {
        const mappedOrders = orders
          .sort((first, second) => {
            const [[key, asc]] = Object.entries(orderBy);

            const { f, s } = this.convertOrderValuesByType(
              first,
              second,
              key,
              orderByType
            );

            if (asc) return f < s ? -1 : 1;
            else f > s ? -1 : 1;
          })
          .map(
            ({
              code,
              seller,
              client,
              created_at,
              invoice,
              total_amount,
              payments,
            }) => [
              code?.toString() || " ",
              `${this.dateFormatter.format(
                new Date(created_at)
              )}\n  ${this.timeFormatter.format(new Date(created_at))}`,
              client?.legal?.social_name || client?.name || " ",
              client?.legal?.company_name || " ",

              this.currencyFormatter.format(
                this.sumAmountPaymentOrders(payments)
              ),
              this.currencyFormatter.format(
                this.sumPaidOrderPayments(payments)
              ),
              this.currencyFormatter.format(
                Number(total_amount) - this.sumPaidOrderPayments(payments)
              ),
              invoice?.nfe || " ",
              payments
                ?.map(
                  ({ expires_at }) =>
                    (expires_at &&
                      this.dateFormatter.format(new Date(expires_at))) ||
                    "sem vencimento"
                )
                .join("\n"),
              seller?.name || " ",
            ]
          );

        mappedOrders.push([
          "Total",
          " ",
          " ",
          " ",
          this.currencyFormatter.format(
            orders.reduce(
              (t, { payments }) => t + this.sumAmountPaymentOrders(payments),
              0
            )
          ),
          this.currencyFormatter.format(
            orders.reduce(
              (t, { payments }) => t + this.sumPaidOrderPayments(payments),
              0
            )
          ),
          this.currencyFormatter.format(
            orders.reduce(
              (t, { payments, total_amount }) =>
                t + Number(total_amount) - this.sumPaidOrderPayments(payments),
              0
            )
          ),
        ]);

        return mappedOrders;
      });

      mappedOrders.push([
        [
          "Total de Pedidos",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          Object.values(this.GroupedOrders).flat().length,
        ],
        [
          "Valor Total",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          this.currencyFormatter.format(
            Object.values(this.GroupedOrders)
              .flat()
              .reduce((t, { total_amount }) => t + Number(total_amount), 0)
          ),
        ],
        [
          "Valor Pago",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          "",
          this.currencyFormatter.format(
            Object.values(this.GroupedOrders)
              .flat()
              .reduce(
                (t, { payments }) => t + this.sumPaidOrderPayments(payments),
                0
              )
          ),
        ],
      ]);

      return mappedOrders;
    },
    generateTableHeaders() {
      return [
        [
          "pedido",
          "data pedido",
          "cliente",
          "razão social",
          "valor",
          "pago",
          "restante",
          "nf",
          "vencimento",
          "vendedor",
        ],
      ];
    },
    createPDF(orderBy, orderByType) {
      const headers = this.generateTableHeaders();
      const content = this.generatePDFContent(orderBy, orderByType);

      const headerMessage = `Relatório de Pedidos - ${this.dateFormatter.format(
        this.day
      )}`;

      generatePDF({
        fileName: `Relatório de Pedidos - ${this.dateFormatter
          .format(this.day)
          .replace(/\//g, "-")}.pdf`,
        headerMessage,
        headers,
        content,
        isMultipleTables: true,
        addTableSummary: true,
        addTableTitle: true,
        defaultFontSize: 8,
      });
    },
    isDelayed(order) {
      return order.deliver_at < new Date().toISOString();
    },
    getStatusType(order) {
      if (
        this.isDelayed(order) &&
        ["created", "accepted", "production", "delayed"].includes(order.status)
      )
        order.status = "delayed";

      if (order.deleted_at) order.status = "canceled";
      return ORDER_STATUS_TYPES[order.status];
    },
    async fetchOrders(showLoading = false) {
      if (showLoading) this.isLoading = true;

      const { orders } = await OrderService().index({
        ...this.filterOptions,
      });

      if (orders) this.orders = orders;

      this.isLoading = false;
    },
    sumAmountPaymentOrders(payments) {
      return payments?.reduce((t, { amount }) => t + Number(amount), 0) || 0;
    },
    sumPaidOrderPayments(payments) {
      return (
        payments?.reduce(
          (t, { amount, paid_at }) => (paid_at ? t + Number(amount) : t),
          0
        ) || 0
      );
    },
  },
};
const ORDER_STATUS_TYPES = {
  created: "info",
  production: null,
  analisys: "warning",
  accepted: null,
  ready: null,
  delayed: "danger",
  canceled: "danger",
  delivered: "success",
  completed: "info",
  finalized: "success",
};
</script>
<style scoped>
.el-card {
  width: 100%;
}

.mt-1 {
  margin-top: 5px;
}
</style>
